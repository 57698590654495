<template>
    <ion-page>
        <ChiTietLenhVue
            ref="ChiTietLenhVue"
            v-if="isChiTiet"
            :isChiTiet="isChiTiet"
            :DaDangKySuDungLenh="DaDangKySuDungLenh"
            :LoadXongDuLieu="LoadXongDuLieu"
            v-model:hiddenFloatBtn="hiddenFloatBtn"
            :MaChuyenDi="ChiTiet.MaChuyenDi"
            :ChiTietLenhDangThucHien="ChiTiet.ChiTietLenhDangThucHien"
            :model="ChiTiet.model"
            @QuayLaiDanhSachLenh="QuayLaiDanhSachLenh"
            @layChiTietLenhDangThucHienAction="layChiTietLenhDangThucHienAction"
            @NhanLenh="NhanLenh"
            :modelDangThucHien="ChiTiet.modelDangThucHien"
            :laLenhGiay="laLenhGiay"
        />
        <!-- @reload="LayChiTietLenh" -->
        <DanhSachLenhVue
            ref="DanhSachLenhVue"
            v-else-if="isDanhSach"
            :ChiTietLenhDangThucHien="ChiTiet.ChiTietLenhDangThucHien"
            :isDanhSach="isDanhSach"
            @QuayLaiChiTietLenh="QuayLaiChiTietLenh"
            @layChiTietLenhDangThucHienAction="layChiTietLenhDangThucHienAction"
            @QuayLaiChiTietLenhDangThucHien="QuayLaiChiTietLenhDangThucHien"
            @NhanLenh="NhanLenh"
            @TuChoiLenh="TuChoiLenh"
            @ClickXeDenBen="ClickXeDenBen"
            :gioXuatBen="gioXuatBen"
        />

        <!-- ====================================Popup chuyển lệnh ========================================= -->
        <PopupVue
            height="auto"
            v-if="ifDialogXacNhanChuyenLenh"
            v-model:dialog="dialogXacNhanChuyenLenh"
            title=""
            buttonTextLeft="Hủy"
            class="popup-padding-8"
            @close="XacNhanChuyenLenh"
            heightScrollView="auto"
        >
            <template #content>
                <ThongBaoVue
                    :message="ParamThongBao.message"
                    :state="ParamThongBao.state"
                    :title="ParamThongBao.title"
                />
            </template>
        </PopupVue>
        <PopupVue
            height="auto"
            v-if="ifDialogDanhSachVeChuyenLenh"
            v-model:dialog="dialogDanhSachVeChuyenLenh"
            title=""
            :fullWidth="false"
            :column="false"
            class="popup-padding-bottom-8"
            buttonTextLeft="Hủy"
            @close="XacNhanChuyenLenh"
            heightScrollView="auto"
        >
            <template #content>
                <PopupDanhSachVeChuyenLenhVue
                    :DanhSachHanhKhachMuaVe="DanhSachHanhKhachMuaVe"
                    v-model:DanhSachDonHang="DanhSachDonHang"
                />
            </template>
        </PopupVue>
        <!-- ====================================Popup tiếp nhận lệnh ========================================= -->
        <PopupVue
            height="auto"
            v-if="ifDialogTiepNhanLenh"
            v-model:dialog="dialogTiepNhanLenh"
            :title="titleNhanLenh"
            buttonTextRight="Xác nhận"
            buttonTextLeft="Hủy"
            heightScrollView="0px"
            @close="closeTiepNhanLenh"
            classPopup="popup-padding-16"
        >
        </PopupVue>

        <!-- ====================================Popup Từ chối lệnh ========================================= -->
        <PopupVue
            height="auto"
            v-if="ifDialogTuChoiLenh"
            v-model:dialog="dialogTuChoiLenh"
            title="Từ chối lệnh"
            buttonTextRight="Xác nhận"
            buttonTextLeft="Hủy"
            classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16 popup-padding-top-16"
            @close="closeTuChoiLenh"
        >
            <template #content>
                <PopupTuChoiLenhVue
                    :data="LenhDangChon"
                    ref="TuChoiLenh"
                    :key="`${componentKey}-1`"
                />
            </template>
        </PopupVue>

        <!-- ====================================Popup xác nhận hoàn thành lệnh ========================================= -->
        <PopupVue
            height="auto"
            v-if="ifDialogKetThucChuyenDi"
            v-model:dialog="dialogKetThucChuyenDi"
            title=""
            buttonTextLeft="Hủy"
            class="popup-padding-8"
            @close="xacNhanKetThucChuyenDi"
            heightScrollView="auto"
        >
            <template #content>
                <ThongBaoVue
                    :message="ParamThongBao.message"
                    :state="ParamThongBao.state"
                    :title="ParamThongBao.title"
                />
            </template>
        </PopupVue>

        <!-- ====================================Xác nhận chuyển trang Danh sách lệnh cần hoàn thành ========================================= -->
        <PopupVue
            height="auto"
            v-if="ifDialogChuyenTrangHoanThanhTatCaLenh"
            v-model:dialog="dialogChuyenTrangHoanThanhTatCaLenh"
            title=""
            buttonTextRight="Tiếp tục"
            buttonTextLeft="Hủy"
            heightScrollView="auto"
            @close="closeChuyenTrangHoanThanhTatCaLenh"
            classPopup="popup-padding-16"
        >
            <template #content>
                <ThongBaoVue
                    :message="ParamThongBao.message"
                    :state="ParamThongBao.state"
                    :title="ParamThongBao.title"
                />
            </template>
        </PopupVue>
    </ion-page>
</template>
<script>
import { IonPage, onIonViewWillEnter, onIonViewWillLeave } from "@ionic/vue";
import ChiTietLenhVue from "./components/ChiTietLenh";
import DanhSachLenhVue from "./components/DanhSachLenh";
import { mapActions, mapState } from "vuex";
import LenhDienTu from "../../../class/LenhDienTu";
import PopupVue from "../../../src/components/_Common/Popup";
import ThongBaoVue from "../../../src/components/_Common/ThongBao";
import LaiXe from "../../../class/LaiXe";
import PopupDanhSachVeChuyenLenhVue from "./components/PopupDanhSachVeChuyenLenh.vue";
import PopupTuChoiLenhVue from "./components/PopupTuChoiLenh";
export default {
    components: {
        DanhSachLenhVue,
        IonPage,
        ChiTietLenhVue,
        PopupVue,
        ThongBaoVue,
        PopupDanhSachVeChuyenLenhVue,
        PopupTuChoiLenhVue,
    },
    data() {
        return {
            isDanhSach: false,
            isChiTiet: false,
            DaDangKySuDungLenh: false,
            LoadXongDuLieu: false,
            hiddenFloatBtn: true,
            ChiTiet: {
                MaChuyenDi: "",
                ChiTietLenhDangThucHien: {},
                model: {
                    guidLenh: "",
                    MaTuyen: "",
                    TenBenDi: "",
                    TenBenDen: "",
                    SoKhachDaMuaVe: 0,
                    SoKhachDangTrenXe: 0,
                    TrangThai: "",
                    BienSo: null,
                    GioXuatBenKeHoach: new Date(),
                    MaMau: "#000",
                    IdTrangThai: null,
                },
                modelDangThucHien: {
                    guidLenh: "",
                    MaTuyen: "",
                    TenBenDi: "",
                    TenBenDen: "",
                    SoKhachDaMuaVe: 0,
                    SoKhachDangTrenXe: 0,
                    TrangThai: "",
                    BienSo: null,
                    GioXuatBenKeHoach: new Date(),
                    MaMau: "#000",
                    IdTrangThai: null,
                },
            },
            gioXuatBen: new Date(),
            LenhDangChon: {},
            itemLenhChuyen: {},
            titleNhanLenh: null,
            ifDialogTiepNhanLenh: false,
            dialogTiepNhanLenh: false,
            dialogXacNhanChuyenLenh: false,
            ifDialogXacNhanChuyenLenh: false,
            dialogDanhSachVeChuyenLenh: false,
            ifDialogDanhSachVeChuyenLenh: false,
            ifDialogTuChoiLenh: false,
            dialogTuChoiLenh: false,
            ifDialogChuyenTrangHoanThanhTatCaLenh: false,
            dialogChuyenTrangHoanThanhTatCaLenh: false,
            ifDialogKetThucChuyenDi: false,
            dialogKetThucChuyenDi: false,
            DanhSachHanhKhachMuaVe: [],
            DanhSachDonHang: [],
            dataKetThucChuyendi: {},
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            laLenhGiay: false,
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
    },
    watch: {
        dialogTuChoiLenh() {
            if (!this.dialogTuChoiLenh) {
                this.$refs.TuChoiLenh.model.LyDo = "";
                this.$refs.TuChoiLenh.$refs.formValidation.instance.reset();
                this.NgatDOMPopup("ifDialogTuChoiLenh");
            }
        },
        dialogCalendar() {
            if (!this.dialogCalendar) {
                this.NgatDOMPopup("ifDialogCalendar");
            }
        },
        dialogThongBao() {
            if (!this.dialogThongBao) {
                this.NgatDOMPopup("ifDialogThongBao");
            }
        },
        dialogKetThucChuyenDi() {
            if (!this.dialogKetThucChuyenDi) {
                this.NgatDOMPopup("ifDialogKetThucChuyenDi");
            }
        },
        dialogTiepNhanLenh() {
            if (!this.dialogTiepNhanLenh) {
                this.NgatDOMPopup("ifDialogTiepNhanLenh");
            }
        },
        dialogChuyenTrangHoanThanhTatCaLenh() {
            if (!this.dialogChuyenTrangHoanThanhTatCaLenh) {
                this.NgatDOMPopup("ifDialogChuyenTrangHoanThanhTatCaLenh");
            }
        },
        dialogXacNhanChuyenLenh() {
            if (!this.dialogXacNhanChuyenLenh) {
                this.NgatDOMPopup("ifDialogXacNhanChuyenLenh");
            }
        },
        dialogDanhSachVeChuyenLenh() {
            if (!this.dialogDanhSachVeChuyenLenh) {
                this.NgatDOMPopup("ifDialogDanhSachVeChuyenLenh");
            }
        },
    },
    methods: {
        ...mapActions("Lenh", ["layChiTietLenhDangThucHien"]),
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        getDataChiTiet() {
            this.resetDataChiTietTatCa();
            this.layChiTietLenhDangThucHienAction();
        },
        async LayChiTietLenh(guidLenh, laLenhGiay) {
            try {
                if (laLenhGiay) {
                    return this.LayChiTietLenhGiay(guidLenh);
                }
                let lenh = new LenhDienTu();
                lenh.guidLenh = guidLenh;
                let rs = await lenh.layChiTietLenh();
                if (!rs) {
                    this.$Helper.ThongBaoToast(
                        "error",
                        "Đã xảy ra lỗi. Vui lòng thử lại!",
                    );
                    return;
                }
                if (!rs.Data.status) {
                    return;
                }
                let chiTietLenh = rs.Data.data;
                this.ChiTiet.MaChuyenDi = chiTietLenh.maLenh || null;
                this.ChiTiet.model.ChiTietLenh = chiTietLenh;
                this.ChiTiet.model.guidLenh = chiTietLenh.guidLenh || null;
                this.ChiTiet.model.TenBenDi = chiTietLenh.benDi || null;
                this.ChiTiet.model.TenBenDen = chiTietLenh.benDen || null;
                this.ChiTiet.model.MaTuyen = chiTietLenh.maTuyen || null;
                this.ChiTiet.model.TrangThai = chiTietLenh.trangThai || null;
                this.ChiTiet.model.MaMau = chiTietLenh.maMauTrangThai.trim() || "#000";
                this.ChiTiet.model.BienSo = chiTietLenh.bienKiemSoat || null;
                this.ChiTiet.model.GioXuatBenKeHoach = chiTietLenh.gioXuatBen;
                this.ChiTiet.model.IdTrangThai = chiTietLenh.idTrangThai || null;

                this.LayThongTinChuyenDiTheoLenh(chiTietLenh.guidLenh);
                // this.getQrCode();
            } catch (error) {
                console.error(error);
            } finally {
                this.LoadXongDuLieu = true;
                if (!this.isChiTiet) {
                    this.isDanhSach = true;
                }
            }
        },
        async LayChiTietLenhGiay(guidLenh) {
            try {
                let rs = await this.$Events.RequestGet({
                    ServicesBE: "LenhMobile",
                    url: this.$t("url.LayChiTietLenhGiay"),
                    dataGet: {
                        GuidLenh: guidLenh,
                    },
                    msgSuccess: null,
                    msgError: "Lấy chi tiết lệnh giấy thất bại!",
                    msgCatch: "Lấy chi tiết lệnh giấy thất bại!",
                });
                console.log("🚀 ~ file: index.vue:346 ~ LayChiTietLenhGiay ~ rs:", rs);
                if (rs.status) {
                    let chiTietLenh = rs.data;
                    this.ChiTiet.MaChuyenDi = chiTietLenh.maLenh || null;
                    this.ChiTiet.model.ChiTietLenh = chiTietLenh;
                    this.ChiTiet.model.guidLenh = chiTietLenh.guidLenh || null;
                    this.ChiTiet.model.TenBenDi = chiTietLenh.benDi || null;
                    this.ChiTiet.model.TenBenDen = chiTietLenh.benDen || null;
                    this.ChiTiet.model.MaTuyen = chiTietLenh.maTuyen || null;
                    this.ChiTiet.model.TrangThai = chiTietLenh.trangThai || null;
                    this.ChiTiet.model.MaMau =
                        chiTietLenh.maMauTrangThai.trim() || "#000";
                    this.ChiTiet.model.BienSo = chiTietLenh.bienKiemSoat || null;
                    this.ChiTiet.model.GioXuatBenKeHoach = chiTietLenh.gioXuatBen;
                    this.ChiTiet.model.IdTrangThai = chiTietLenh.idTrangThai || null;
                }
            } catch (error) {
                console.log("🚀 ~ error:", error);
            }
        },
        async layChiTietLenhDangThucHienAction() {
            try {
                let rs = await this.layChiTietLenhDangThucHien();
                if (!rs) {
                    this.$Helper.ThongBaoToast(
                        "error",
                        "Đã xảy ra lỗi. Vui lòng thử lại!",
                    );
                    return;
                }
                if (!rs.Data.status) {
                    this.ChiTiet.modelDangThucHien.ChiTietLenh = rs.Data.data;
                    return;
                }
                let chiTietLenh = rs.Data.data;
                this.ChiTiet.MaChuyenDi = chiTietLenh.maLenh || null;
                this.ChiTiet.modelDangThucHien.ChiTietLenh = chiTietLenh;
                this.ChiTiet.modelDangThucHien.guidLenh = chiTietLenh.guidLenh || null;
                this.ChiTiet.modelDangThucHien.TenBenDi = chiTietLenh.benDi || null;
                this.ChiTiet.modelDangThucHien.TenBenDen = chiTietLenh.benDen || null;
                this.ChiTiet.modelDangThucHien.MaTuyen = chiTietLenh.maTuyen || null;
                this.ChiTiet.modelDangThucHien.TrangThai = chiTietLenh.trangThai || null;
                this.ChiTiet.modelDangThucHien.MaMau =
                    chiTietLenh.maMauTrangThai.trim() || "#000";
                this.ChiTiet.modelDangThucHien.IdTrangThai =
                    chiTietLenh.idTrangThai || null;
                this.ChiTiet.modelDangThucHien.BienSo = chiTietLenh.bienKiemSoat || null;
                this.ChiTiet.modelDangThucHien.GioXuatBenKeHoach = chiTietLenh.gioXuatBen;
                this.ChiTiet.ChiTietLenhDangThucHien = chiTietLenh;
                this.LayThongTinChuyenDiTheoLenh(chiTietLenh.guidLenh);
                // this.getQrCode();
            } catch (error) {
                console.error(error);
            } finally {
                this.hiddenFloatBtn = true;
                this.LoadXongDuLieu = true;
                if (!this.isChiTiet) {
                    this.isDanhSach = true;
                }
            }
        },
        resetDataChiTiet() {
            this.ChiTiet.ChiTietLenhDangThucHien = {};
            this.ChiTiet.MaChuyenDi = null;
            this.ChiTiet.model.TenBenDi = null;
            this.ChiTiet.model.TenBenDen = null;
            this.ChiTiet.model.MaTuyen = null;
            this.ChiTiet.model.TrangThai = null;
            this.ChiTiet.model.MaMau = null;
            this.ChiTiet.model.BienSo = null;
            this.ChiTiet.model.GioXuatBenKeHoach = null;
        },
        resetDataChiTietDangThucHien() {
            this.ChiTiet.ChiTietLenhDangThucHien = {};
            this.ChiTiet.MaChuyenDi = null;
            this.ChiTiet.modelDangThucHien.TenBenDi = null;
            this.ChiTiet.modelDangThucHien.TenBenDen = null;
            this.ChiTiet.modelDangThucHien.MaTuyen = null;
            this.ChiTiet.modelDangThucHien.TrangThai = null;
            this.ChiTiet.modelDangThucHien.MaMau = null;
            this.ChiTiet.modelDangThucHien.BienSo = null;
            this.ChiTiet.modelDangThucHien.GioXuatBenKeHoach = null;
        },
        resetDataChiTietTatCa() {
            this.ChiTiet.ChiTietLenhDangThucHien = {};
            this.ChiTiet.MaChuyenDi = null;

            this.ChiTiet.model.TenBenDi = null;
            this.ChiTiet.model.TenBenDen = null;
            this.ChiTiet.model.MaTuyen = null;
            this.ChiTiet.model.TrangThai = null;
            this.ChiTiet.model.MaMau = null;
            this.ChiTiet.model.BienSo = null;
            this.ChiTiet.model.GioXuatBenKeHoach = null;

            this.ChiTiet.modelDangThucHien.TenBenDi = null;
            this.ChiTiet.modelDangThucHien.TenBenDen = null;
            this.ChiTiet.modelDangThucHien.MaTuyen = null;
            this.ChiTiet.modelDangThucHien.TrangThai = null;
            this.ChiTiet.modelDangThucHien.MaMau = null;
            this.ChiTiet.modelDangThucHien.BienSo = null;
            this.ChiTiet.modelDangThucHien.GioXuatBenKeHoach = null;
        },
        async LayThongTinChuyenDiTheoLenh(idLenhDienTu) {
            try {
                let rs = await this.$store.dispatch(
                    "ThongTin/LayThongTinChuyenDiTheoLenh",
                    idLenhDienTu,
                );
                if (rs.Data.status === true) {
                    let ThongTinChuyenDi = rs.Data.data;
                    this.ChiTiet.model.SoKhachDaMuaVe =
                        ThongTinChuyenDi.soKhachMuaVe || 0;
                    this.ChiTiet.model.SoKhachDangTrenXe =
                        ThongTinChuyenDi.soKhachTrenXe || 0;
                    this.ChiTiet.modelDangThucHien.SoKhachDaMuaVe =
                        ThongTinChuyenDi.soKhachMuaVe || 0;
                    this.ChiTiet.modelDangThucHien.SoKhachDangTrenXe =
                        ThongTinChuyenDi.soKhachTrenXe || 0;
                }
            } catch (error) {
                console.error(error);
            }
        },
        QuayLaiDanhSachLenh() {
            this.$router.push({
                path: "/tabs/LenhDienTu",
            });
            this.hiddenFloatBtn = false;
            this.gioXuatBen =
                this.ChiTiet.model.GioXuatBenKeHoach ||
                this.ChiTiet.modelDangThucHien.GioXuatBenKeHoach;
            setTimeout(() => {
                this.isChiTiet = false;
                this.isDanhSach = true;
            }, 100);
        },
        QuayLaiChiTietLenh(guidLenh, laLenhGiay) {
            this.laLenhGiay = laLenhGiay || false;
            this.LoadXongDuLieu = false;
            this.hiddenFloatBtn = false;
            this.isDanhSach = false;
            this.isChiTiet = true;
            this.resetDataChiTietTatCa();
            this.$router.push({
                path: "/tabs/LenhDienTu",
                query: {
                    isChiTiet: true,
                    guidLenh: guidLenh,
                    laLenhGiay: laLenhGiay || false,
                },
            });
            this.LayChiTietLenh(guidLenh, laLenhGiay);
            this.layChiTietLenhDangThucHienAction();
        },
        QuayLaiChiTietLenhDangThucHien() {
            this.LoadXongDuLieu = false;
            this.hiddenFloatBtn = false;
            this.isDanhSach = false;
            this.isChiTiet = true;
            this.resetDataChiTietTatCa();
            this.layChiTietLenhDangThucHienAction();
        },
        NhanLenh(item) {
            return this.KiemTraTruocKhiTiepNhanLenh(item);
        },
        async KiemTraTruocKhiTiepNhanLenh(item) {
            try {
                this.LenhDangChon = item;
                this.itemLenhChuyen = item;
                let rs = await this.$Events.RequestGet({
                    ServicesBE: "LenhMobile",
                    url: this.$t("urlLXQuanLyLenh.LaiXeKiemTraTruocKhiTiepNhanLenh"),
                    dataGet: null,
                    msgSuccess: "",
                    msgError: "",
                    msgCatch: "",
                });
                if (!rs.status) {
                    if (rs.errorCode == 1214) {
                        this.DanhSachLenhCanHoanThanh = rs.data || [];
                        localStorage.setItem(
                            "DanhSachLenhCanHoanThanh",
                            JSON.stringify(this.DanhSachLenhCanHoanThanh),
                        );
                        this.$router.push({
                            path: "/Danh-Sach-Lenh-Can-Hoan-Thanh",
                            query: {
                                prePath: this.isChiTiet
                                    ? "/tabs/LenhDienTu" + location.search
                                    : "/tabs/LenhDienTu?gioXuatBen=" +
                                      new Date(
                                          this.$refs.DanhSachLenhVue.filter.NgayXuatBenKH,
                                      ).getTime(),
                            },
                        });
                        this.$Helper.ThongBaoToast(
                            "warning",
                            "Lái xe có chuyến đi chưa hoàn thành!",
                        );
                        // this.ParamThongBao.title = `Lái xe có chuyến đi chưa hoàn thành!`;
                        // this.ParamThongBao.message = `Bạn cần hoàn thành tất cả các chuyến đi trước khi tiếp nhận lệnh mới, bạn có muốn tiếp tục?`;
                        // this.ParamThongBao.state = "Warning";
                        // this.ifDialogChuyenTrangHoanThanhTatCaLenh = true;
                        // this.dialogChuyenTrangHoanThanhTatCaLenh = true;
                    } else if (rs.errorCode == 1215) {
                        if (rs.data && rs.data.length > 0) {
                            this.ChonLenhChuyen(rs.data[0]);
                        } else {
                            this.$Helper.ThongBaoPopup({
                                message_title:
                                    "Lấy danh sách hành khách mua vé trên chuyến đi đang thực hiện gặp lỗi!",
                                message: "Không tìm thấy chuyến đi đang thực hiện!",
                            });
                        }
                    } else {
                        this.$Helper.ThongBaoToast("error", rs.message);
                    }
                } else {
                    this.titleNhanLenh = `Bạn có chắc muốn tiếp nhận lệnh điện tử ${item.maLenh}?`;
                    this.ifDialogTiepNhanLenh = true;
                    this.dialogTiepNhanLenh = true;
                }
            } catch (error) {
                console.log("🚀 ~ file: error:", error);
            }
        },
        async ChonLenhChuyen(item) {
            this.LenhDangChon = this.itemLenhChuyen;
            let SuDungDichVu = this.$Core.AuthApi.TokenParsed.SuDungDichVu || null;
            if (SuDungDichVu) {
                let arrDichVu = SuDungDichVu.split(";");
                if (arrDichVu.some((e) => e == this.$t("StringCheck.SuDungVe"))) {
                    this.LayDanhSachHanhKhachMuaVeTrenXe(item);
                } else {
                    this.DanhSachDonHang = [];
                    this.ThongBaoXacNhanChuyenLenh();
                }
            }
        },
        async LayDanhSachHanhKhachMuaVeTrenXe(item) {
            try {
                let rs = await this.$store.dispatch(
                    "Lenh/LayDanhSachHanhKhachMuaVeTrenXe",
                    item.idLenhDienTu,
                );
                if (rs.Data.status == true) {
                    this.DanhSachHanhKhachMuaVe = rs.Data.data;
                    if (this.DanhSachHanhKhachMuaVe.length == 0) {
                        this.DanhSachDonHang = [];
                        this.ThongBaoXacNhanChuyenLenh();
                    } else {
                        this.ifDialogDanhSachVeChuyenLenh = true;
                        this.dialogDanhSachVeChuyenLenh = true;
                    }
                } else {
                    this.$Helper.ThongBaoToast("error", rs.Data.message);
                }
            } catch (ex) {
                console.log("🚀 ex", ex);
                this.$Helper.ThongBaoToast("error", "Lỗi hệ thống!");
            }
        },
        ThongBaoXacNhanChuyenLenh() {
            this.ParamThongBao.title = `Bạn đang thực hiện lệnh khác!`;
            this.ParamThongBao.message = `Bạn có chắc chắn muốn chuyển sang lệnh?`;
            this.ParamThongBao.state = "Warning";
            this.ifDialogXacNhanChuyenLenh = true;
            this.dialogXacNhanChuyenLenh = true;
        },
        async LaiXeChuyenDoiChuyenDi() {
            try {
                this.$startLoading;
                let DanhSachDonHang = [];
                this.DanhSachDonHang.forEach((e) => {
                    if (!DanhSachDonHang.some((x) => x == e)) {
                        DanhSachDonHang.push(e);
                    }
                });
                let query = {
                    idLenhCu: this.ChiTiet.ChiTietLenhDangThucHien.guidLenh,
                    idLenhMoi: this.LenhDangChon.guidLenh,
                    idDonHangs: DanhSachDonHang,
                    ToaDo: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                };
                let rs = await this.$store.dispatch("Lenh/LaiXeChuyenDoiChuyenDi", query);
                if (rs.Data.status == true) {
                    this.dialogXacNhanChuyenLenh = false;
                    this.dialogDanhSachVeChuyenLenh = false;
                    this.QuayLaiChiTietLenh(this.LenhDangChon.guidLenh);
                    this.$Helper.ThongBaoToast("success", "Chuyển đổi lệnh thành công!");
                } else {
                    this.$Helper.ThongBaoPopup({
                        message_title: "Chuyển đổi lệnh thất bại!",
                        message: rs.Data.message,
                    });
                }
            } catch (ex) {
                console.log("🚀 ex", ex);
                this.$Helper.ThongBaoToast("error", "Lỗi hệ thống!");
            } finally {
                this.$stopLoading;
            }
        },
        async XacNhanChuyenLenh(param) {
            if (!param) {
                this.dialogXacNhanChuyenLenh = false;
                this.dialogDanhSachVeChuyenLenh = false;
                return;
            }
            this.LaiXeChuyenDoiChuyenDi();
        },
        async closeTiepNhanLenh(param) {
            if (!param) {
                this.dialogTiepNhanLenh = false;
                return;
            }
            try {
                this.$startLoading;
                let rs = await new LaiXe().tiepNhanLenh(
                    this.LenhDangChon.guidLenh,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                this.dialogTiepNhanLenh = false;
                this.$stopLoading;

                if (!rs) {
                    throw this.$Helper.ThongBaoToast("error", "Lỗi hệ thống!");
                }
                if (!rs.Data.status) {
                    this.$Helper.ThongBaoToast("error", rs.Data.message);
                    // if (!rs.Data.errorCode == 1212) {
                    // } else {
                    //     this.ChonLenhChuyen();
                    // }
                    return;
                }

                this.$Helper.ThongBaoToast("success", "Tiếp nhận lệnh thành công!");
                // this.$refs.DanhSachLenhVue.onItemClickTab(
                //     this.$refs.DanhSachLenhVue.filter.TrangThai,
                // );
                this.QuayLaiChiTietLenh(this.LenhDangChon.guidLenh);
            } catch (error) {
                console.log("🚀 ~ error:", error);
                this.$stopLoading;
                this.$Helper.ThongBaoToast("error", "Lỗi hệ thống!");
            }
        },
        TuChoiLenh(item) {
            this.LenhDangChon = item;
            this.ifDialogTuChoiLenh = true;
            this.dialogTuChoiLenh = true;
        },
        async closeTuChoiLenh(param) {
            if (!param) {
                this.dialogTuChoiLenh = false;
                return;
            }
            try {
                let valid = this.$refs.TuChoiLenh.$refs.formValidation.instance.validate();
                if (!valid.isValid) {
                    return this.$Helper.autoFocusError(valid);
                }
                let laiXe = new LaiXe();
                laiXe.lyDo = this.$refs.TuChoiLenh.model.LyDo;
                let rs = await laiXe.tuChoiLenh(
                    this.LenhDangChon.guidLenh,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                this.dialogTuChoiLenh = false;
                if (!rs) {
                    throw this.$Helper.ThongBaoToast("error", "Lỗi hệ thống!");
                }
                if (!rs.Data.status) {
                    return this.$Helper.ThongBaoToast("error", rs.Data.message);
                }
                this.$Helper.ThongBaoToast("success", "Từ chối lệnh thành công!");
                this.$refs.DanhSachLenhVue.onItemClickTab(
                    this.$refs.DanhSachLenhVue.filter.TrangThai,
                );
            } catch (error) {
                console.log("🚀 ~ error:", error);
                this.$Helper.ThongBaoToast("error", "Lỗi hệ thống!");
            }
        },
        closeChuyenTrangHoanThanhTatCaLenh(param) {
            this.dialogChuyenTrangHoanThanhTatCaLenh = false;
            if (!param) {
                return;
            }
            localStorage.setItem(
                "DanhSachLenhCanHoanThanh",
                JSON.stringify(this.DanhSachLenhCanHoanThanh),
            );
            this.$router.push({
                path: "/Danh-Sach-Lenh-Can-Hoan-Thanh",
                query: {
                    prePath: this.isChiTiet
                        ? "/tabs/LenhDienTu" + location.search
                        : "/tabs/LenhDienTu",
                },
            });
        },
        ClickXeDenBen(data) {
            this.dataKetThucChuyendi = data;
            this.ParamThongBao = {
                state: "Warning",
                title: "Xác nhận xe đã đến bến",
                message: "Bạn có chắc chắn đã kết thúc chuyến đi?",
            };
            this.ifDialogKetThucChuyenDi = true;
            this.dialogKetThucChuyenDi = true;
        },
        async xacNhanKetThucChuyenDi(param) {
            if (!param) {
                this.dialogKetThucChuyenDi = false;
                return;
            }
            if (!this.dataKetThucChuyendi || !this.dataKetThucChuyendi.guidLenh) {
                this.$Helper.ThongBaoToast("error", "Vui lòng kiểm tra lại thông tin!");
                this.dialogKetThucChuyenDi = false;
                return;
            }
            try {
                let dataPost = {
                    idLenh: this.dataKetThucChuyendi.guidLenh,
                    ToaDo: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                };
                let rs = await this.$store.dispatch("Lenh/KetThucChuyenDi", dataPost);
                if (rs.Data.status == true) {
                    this.dialogKetThucChuyenDi = false;
                    this.$Helper.ThongBaoToast(
                        "success",
                        "Kết thúc chuyến đi thành công!",
                    );
                    this.$router.push({
                        path: "/DanhSachLenh",
                    });
                } else {
                    this.$Helper.ThongBaoToast("error", rs.Data.message);
                }
            } catch (error) {
                console.log("🚀 = error", error);
                this.$Helper.ThongBaoToast("error", "Lỗi hệ thống!");
            }
        },
    },
    created() {
        onIonViewWillEnter(async () => {
            let query = this.$route.query;
            this.gioXuatBen = query.gioXuatBen
                ? new Date(parseInt(query.gioXuatBen))
                : new Date();
            this.isChiTiet = query.isChiTiet;
            this.DaDangKySuDungLenh = false;
            this.LoadXongDuLieu = false;
            this.hiddenFloatBtn = false;
            this.isDanhSach = false;

            let SuDungDichVu = this.$Core.AuthApi.TokenParsed.SuDungDichVu || null;
            if (SuDungDichVu) {
                let arrDichVu = SuDungDichVu.split(";");
                arrDichVu.forEach(async (e) => {
                    if (e == this.$t("StringCheck.SuDungLenh")) {
                        this.DaDangKySuDungLenh = true;
                        if (this.$route.query.guidLenh) {
                            this.QuayLaiChiTietLenh(
                                this.$route.query.guidLenh,
                                JSON.parse(this.$route.query.laLenhGiay || "false"),
                            );
                        } else {
                            this.getDataChiTiet();
                        }
                    }
                });
            } else {
                if (this.$route.query.guidLenh) {
                    this.QuayLaiChiTietLenh(
                        this.$route.query.guidLenh,
                        JSON.parse(this.$route.query.laLenhGiay || "false"),
                    );
                } else {
                    this.getDataChiTiet();
                }
            }
        });
    },
    mounted() {
        onIonViewWillLeave(() => {
            this.isDanhSach = false;
            this.isChiTiet = false;
        });
    },
    beforeRouteLeave() {
        // this.hiddenFloatBtn = false;
        this.emitter.emit("hiddenFloatBtn", false);
    },
};
</script>
<style scoped></style>
